@import url('https://cdn.jsdelivr.net/npm/tailwindcss/dist/tailwind.min.css');
@import url('https://cdn.jsdelivr.net/gh/cityofnewyork/nyco-patterns@v2.2.0/dist/styles/nyco-patterns-default.css');

@import 'animate.scss/vendor/assets/stylesheets/animate/fadeOutDown';

.mt-half {
  margin-top: 0.125rem;
}

.ml-half {
  margin-left: 0.125rem;
}

.contract-question {
  padding: 12px;
  border-color: #ACAEB9;
}


h4.text-primary-blue {
  color: #3155a6;
}

.disabled-grey {
  background-color: rgba(172, 174, 185, 0.4) !important;
}

hr {
    margin-top: 0.75rem;
    height: 1px;
    /* Set the hr color */
    color: #333333b0; /* old IE */
    background-color: #333333b0; /* Modern Browsers */
}

@media (min-width: 768px) {
  .screen-tablet\:layout--sidebar > *:first-child {
    width: 8rem;
  }
}

.full-select {
  width: 100% !important;
}

@media (min-width: 768px) {
  .o-header__banner-main {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.numbered-list {
  padding: 0;
  counter-reset: item;
  list-style-type: decimal;
}

.numbered-list > .numbered-list-elem {
  padding: 0 0 0 2em;
  text-indent: -2em;
  list-style-type: none;
  counter-increment: item;
}

.numbered-list > .numbered-list-elem:before {
  display: inline-block;
  width: 1em;
  padding-right: 0.5em;
  font-weight: bold;
  text-align: right;
  content: counter(item) ".";
}

.MuiTableCell-head {
  background-color: white;
}

.checkbox-padding {
  padding-top: 0.2rem;
}

.buttonLink {
    font-weight: 700;
    color: #3155a6;
    outline: none;
}

.buttonLink:hover {
  color: #2F334F
}

.hideUnderline .MuiInput-underline:after {
  display: none;
}

.hideUnderline .MuiInput-underline:before {
  display: none;
}

.layout--sidebar > *:first-child {
    width: 13rem;
}

.select-input {
  background-color: white;
  border-color: #ACAEB9;
}

.center {
  margin: auto;
  width: 50%;
}

.center input {
  margin: 0 auto;
}

.center select {
  margin: 0 auto;
}

@media (min-width: 768px) {
  .screen-tablet-header {
    width: 190px !important;
    height: 75px !important;
  }
}

@media (min-width: 768px) {
  .screen-tablet-toolbar {
    width: 190px !important;
  }
}

.toolbar-link {
  padding-left: 5px !important;
}

.logo-padding {
  padding: 8px 20px !important;
}

/* Customize the label (the container) */
.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 25%;
  background-color: white;
  border-width: 1px;
}

/* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark {
  background-color: #2f324e;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
  left: 10px;
  top: 5px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
